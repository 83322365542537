<template>
  <el-container style="height: 100vh">
    <el-aside width="200px">
      <div style="text-align: center; font-size: 18px; padding: 10px 0">
        <img src="../assets/crcgas_logo.png" />
      </div>
      <el-menu
        :default-active="active"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        router
        active-text-color="#ffd04b"
      >
        <el-menu-item index="/system/NewsList" link>
          <i class="el-icon-s-grid"></i>
          <span>文章列表</span>
        </el-menu-item>
        <el-menu-item index="/system/MessageList" link>
          <i class="el-icon-s-comment"></i>
          留言列表</el-menu-item
        >
        <el-menu-item index="/system/myddc" link>
          <i class="el-icon-s-claim"></i>
          满意度调查</el-menu-item
        >
        <el-menu-item index="/system/GhuXhu" link>
          <i class="el-icon-s-release"></i>
          过销户列表</el-menu-item
        >
        <el-menu-item index="/system/BaoZ" link>
          <i class="el-icon-s-release"></i>
          报装列表</el-menu-item
        >
        <el-menu-item index="/system/BaoX" link>
          <i class="el-icon-s-release"></i>
          报修列表</el-menu-item
        >
        <el-menu-item index="/system/YJSList" link>
          <i class="el-icon-s-release"></i>
          一件事报装</el-menu-item
        >
        <el-menu-item index="/system/UserList" link>
          <i class="el-icon-s-custom"></i>
          用户管理</el-menu-item
        >
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div style="width: 100%" align="right">
          您好，{{ username }}
          <el-link
            :underline="false"
            type="primary"
            style="margin-left: 10px; cursor: pointer"
            @click="quitLogin"
            >退出</el-link
          >
        </div>
      </el-header>
      <el-main>
        <el-card shadow="never">
          <router-view></router-view>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      active: "/system/NewsList",
      username: "",
    };
  },
  mounted() {
    this.username = localStorage.getItem("realname");
  },
  watch: {
    $route: {
      handler(val) {
        this.active = val.path;
      },
      immediate: true,
    },
  },
  methods: {
    quitLogin() {
      this.$confirm("确认退出当前账号？", "操作提示").then(() => {
        localStorage.clear();
        this.$router.push("/Login");
      });
    },
  },
};
</script>

<style>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  /* text-align: center; */
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  padding: 10px;
}
.el-menu {
  border: 0;
}
</style>
